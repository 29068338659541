import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useNewsLetter } from "hooks/website/web";
import { Link } from "react-router-dom";
import { Row, Col, Form, message, Button, Input } from "antd";


const WebFooter = () => {

  let navigate = useNavigate();
  const [form] = Form.useForm();

  const { mutate: newsLetter, isLoading: loading } = useNewsLetter();

  const validateMessages = {
    required: "Enter your Email Address!",
    types: {
      email: "Enter your valid Email Address!",
    },
  };

  const onFinish = (values) => {
    const submitData = { ...values };
    submitData.email = values.email;
    submitData.letterSubscription = true;
    let formData = JSON.stringify(submitData);
    newsLetter(formData, {
      onSuccess: (item) => {
        if (item?.status === 200) {
          message.success(item?.data);
          form.resetFields();
        } else {
          message.error(item?.message);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }

  const pageScroll = (navId) => {
    if (window.location.pathname !== "/") {
      navigate("/");
      setTimeout(() => {
        document
          .getElementById(navId)
          .scrollIntoView({ behavior: "smooth", block: "start" });
      }, 200);
    } else {
      document
        .getElementById(navId)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const pageTop = () => {
     window.scrollTo(0,0);
  }

  return (
    <Fragment>
      <footer className="meeting-view-footer">
        <Row className="max-foot">
          <Col md={4} xs={12} lg={6}>
            <h2>Menu</h2>
            <h3 onClick={() =>  pageTop()}><Link to="/">Home</Link></h3>
            <h3 onClick={() => pageScroll("contact")}>Contact</h3>
            <h3 onClick={() => pageScroll("pricing")}>Pricing</h3>
          </Col>
          <Col md={6} xs={12} lg={8}>
            <h2>Utility pages</h2>
            <h3><Link to="/termsandconditions">Terms & Conditions</Link></h3>
            <h3><Link to="/privacypolicy">Privacy Policy</Link></h3>
            <h3><Link to="/faq">FAQ</Link></h3>
          </Col>
          <Col md={14} lg={10} >
            <div className="subscribe-footer">
              <h1>Subscribe to our news letter</h1>
              <h2>Recieve upto info about latest trends and news updates via email.</h2>
              <Form
                form={form}
                name="newsletter"
                validateMessages={validateMessages}
                onFinish={onFinish}
                autoComplete="off"
                labelAlign="vertical"
              >
                <Form.Item
                  label=""
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                    },
                  ]}
                >
                  <Input className="footer_input" placeholder="your email" />
                </Form.Item>
                <Form.Item
                  label=""
                  name="letterSubscription"
                  hidden={true}
                >
                  <Input />
                </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                  >
                    Subscribe
                  </Button>
              </Form>

            </div></Col>
        </Row>
      </footer>
    </Fragment>
  );
};

export default WebFooter;