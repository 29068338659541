import axios from "axios";
import Cookies from "js-cookie";
import { message } from "antd";

const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;
const SECONDS = 30;
const MILISECONDS = 1000;
const TIMEOUT = SECONDS * MILISECONDS;
const TOKEN = Cookies.get("token_set");
const ROLE = localStorage.getItem("role");

const client = axios.create({
  baseURL: REACT_APP_BASE_API,
  timeout: TIMEOUT,
  headers: {
    "content-type": "application/json",
    Authorization: `Bearer ${TOKEN}`,
  },
});

client.interceptors.request.use(function (config) {
  return config;
});

client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status !== 401) {
      message.error(error?.response?.data?.data);
    } else if (error.response.status === 401) {
      Cookies.remove("token_set");
      Cookies.remove("email");
      Cookies.remove("otp");
      window.location.href = "/login";
    } else {
      console.error(error);
    }
    return Promise.reject(error);
  }
);

const profileGetList = async () => {
  if (TOKEN !== undefined && ROLE !== "SUPER_ADMIN") {
    const { data } = await client.get(`admin/profile`);
    return data;
  }
};

const updateProfile = async (formData) => {
  const { data } = await client.put(`admin/registration/editProfile`, formData);
  return data;
};

const updatePassword = async (formData) => {
  const { data } = await client.put(`admin/changePassword`, formData);
  return data;
};

const deleteProfile = async (formData) => {
  const { data } = await client.delete(`admin/profile`, formData);
  return data;
};

export {
  profileGetList,
  updateProfile,
  updatePassword,
  deleteProfile
};
