import React, { Fragment, useState } from "react"
import { Row, Col, Menu, Dropdown, Modal, Form, Input, Button, Drawer } from "antd";
import { KeyOutlined, UserOutlined, AlignRightOutlined, MessageFilled, CaretDownOutlined, ProfileOutlined, ImportOutlined, CreditCardFilled, SettingOutlined } from "@ant-design/icons"
import Image from "helpers/Image";
import { Link, useNavigate } from "react-router-dom";
import "../admin/style.css";
import Cookies from "js-cookie";

const { TextArea } = Input;

const AdminHeader = ({ profileData }) => {

  let navigate = useNavigate();
  const [form] = Form.useForm();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [open, setOpen] = useState(false);

  const onLogout = (e) => {
    e.preventDefault()
    Cookies.remove('token_set');
    Cookies.remove('email');
    Cookies.remove('otp');
    navigate(`/home`);
  }

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showLogoutModal = (e) => {
    e.preventDefault();
    setIsLogout(true);
  };
  const handleLogoutCancel = () => {
    setIsLogout(false);
  };

  const onFinish = (formData) => {
    console.log(formData)
  }

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <h5><b>Account</b></h5>
        <Link to={`/profile/account`} onClick={onClose}>
          <UserOutlined />&nbsp;Account
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to={`/billing/subscription`} onClick={onClose}>
          <CreditCardFilled />&nbsp;Billing
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" >
        <div className="logout" onClick={showLogoutModal}>
          <ImportOutlined />&nbsp;&nbsp;Log Out
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Fragment>
      <Row className="meeting-view-admin-header">
        <Col md={2} xs={3} lg={3} className="txt-right">
          <Link to={`/licenses`}>
            <Image name="logo.png" className="logo-img" />
          </Link>
        </Col>
        <Col md={16} xs={0} lg={12}>
          <ul>
            <li><Link to="/licenses"><KeyOutlined />License</Link></li>
            <li><Link to={`/settings`}><SettingOutlined />Settings</Link></li>
            <li><Link to={`private/documentation/introduction`} state={"privateRoute"} ><ProfileOutlined />Documentation</Link></li>
          </ul>
        </Col>
        <Col md={0} xs={20} lg={0} className="txt-right">
          <AlignRightOutlined onClick={() => showDrawer()} className="toggle-mobile" />
        </Col>
        <Col md={6} xs={0} lg={7} className="txt-right">
          <ul>
            <li>
              <Dropdown overlay={menu}>
                <div className="ant-dropdown-link">
                  {profileData?.data?.companyName} <CaretDownOutlined />
                </div>
              </Dropdown>
            </li>
          </ul>
        </Col>
      </Row>
      <Drawer
        placement="top"
        onClose={onClose}
        visible={open}
        open={open}
      >
        <Col md={0} xs={24} lg={0} className="txt-center">
          <ul className="toggle-lists">
            <li><Link to={`/licenses`} onClick={onClose}><KeyOutlined />Licence</Link></li>
            <li><Link to={`/settings`}><SettingOutlined />Settings</Link></li>
            <li><Link to={`private/documentation/introduction`} state={"privateRoute"} onClick={onClose}><ProfileOutlined />Documentation</Link></li>
            <li>
              <Dropdown overlay={menu} trigger={['click']}>
                <div className="ant-dropdown-link">
                  {profileData?.data?.companyName} <CaretDownOutlined />
                </div>
              </Dropdown>
            </li>
          </ul>
        </Col>
      </Drawer>
      <Modal
        title="Support Ticket"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{
          top: '30px',
        }}
      >
        <Form
          form={form}
          name="modal_Form"
          onFinish={onFinish}
        >
          <Form.Item
            name="mail"
            rules={[
              {
                required: true,
                message: "This field is Required!!",
              },
            ]}
          >
            <Input maxLength={35} placeholder="Your Mail Address" />
          </Form.Item>
          <Form.Item
            name="subject"
            rules={[
              {
                required: true,
                message: "This field is Required!!",
              },
            ]}
          >
            <Input maxLength={35} placeholder="Subject" />
          </Form.Item>
          <Form.Item
            name="message"
            rules={[
              {
                required: true,
                message: "This field is Required!!",
              },
            ]}>
            <TextArea rows={6} maxLength={250} placeholder="Message" />
          </Form.Item>
          <Form.Item
            className="txt-right"
          >
            <Button
              type="primary"
              htmlType="submit"
              className="send-btn"
            >
              <MessageFilled />Send
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        open={isLogout}
        onCancel={handleLogoutCancel}
        closable={false}
        className="logout_popup"
      >
        <p>Are you sure you want to Logout?</p>
        <Row gutter={[16, 16]}>
          <Col md={12}>
            <Button className="no_btn" onClick={handleLogoutCancel} block>
              No
            </Button>
          </Col>
          <Col md={12}>
            <Button className="yes_btn" onClick={(e) => onLogout(e)} block>
              Yes
            </Button></Col>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default AdminHeader;
