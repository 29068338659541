import { message } from "antd";
import axios from "axios";

const REACT_APP_BASE_API = process.env.REACT_APP_BASE_API;
const SECOND = 30;
const MILLISECONDS = 1000;
const TIMEOUT = SECOND * MILLISECONDS;

const client = axios.create({
  baseURL: REACT_APP_BASE_API,
  timeout: TIMEOUT,
  headers: {
    "content-type": "application/json",
  },
});

client.interceptors.request.use(function (config) {
  return config;
});

client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.statusCode !== 401) {
      message.error(error?.response?.data?.data);
    } else if (error.response.statusCode !== 200) {
      message.error(error?.response?.data?.data);
    } else if (error.response.statusCode === 400) {
      message.error(error?.response?.data?.message);
    } else {
      console.error(error);
    }
    return Promise.reject(error);
  }
);

const newsLetter = async (formData) => {
  const { data } = await client.post(`public/newsLetter`, formData);
  return data;
};

export { newsLetter };
