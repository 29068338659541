import React from "react";
import { Outlet } from "react-router-dom";
import { Row, Col } from "antd";
import AdminHeader from "./utils/admin/adminHeader";

export const AppLayout = ({profileData}) => {
  return (
    <div className="meeting-view-admin">
      <Row>
        <Col md={24} xs={24}>
          <AdminHeader  profileData={profileData} />
        </Col>
        <Col md={24} xs={24} className="full-view">
          <Outlet />
        </Col>
      </Row>
    </div>
  );
};

export default AppLayout;
