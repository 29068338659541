import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { useProfileGetList } from "hooks/profile/profile";
import Image from "helpers/Image";

// Layouts
import WebLayout from "layouts/weblayout";
import AuthLayout from "layouts/AuthLayout";
import AppLayout from "layouts/AppLayout";
import AdminLayout from "layouts/SuperAdminLayout";


// web Routes
const Home = lazy(() => import("./views/web/home"));
const TermsAndConditions = lazy(() => import("./views/web/terms-of-service/index"));
const PrivacyPolicy = lazy(() => import("./views/web/privacy-policy/index"));
const Faq = lazy(() => import("./views/web/faq/index"));

// Authentication Routes
const SignIn = lazy(() => import("./views/auth/login"));
const Register = lazy(() => import("./views/auth/registration/index"));
const SetPassword = lazy(() => import("./views/auth/registration/setpasword"));
const Success = lazy(() => import("./views/auth/registration/success"));
const ResetPassword = lazy(() => import("./views/auth/reset/index"));
const ChangePassword = lazy(() => import("./views/auth/reset/changePassword"));
const DeletAccount = lazy(() => import("./views/auth/deleteAccount/index"));
const VerifyAndDelete = lazy(() => import("./views/auth/deleteAccount/verifyOtp"));

// Admin Routes
const License = lazy(() => import("./views/app/license/index"));
const LicenseDetails = lazy(() => import("./views/app/license/licenseview"));
const Device = lazy(() => import("./views/app/devices/index"));
const Configure = lazy(() => import("./views/app/devices/configure"));
const Settings = lazy(() => import("./views/app/devices/global"));
const DocumentationIntroduction = lazy(() => import("./views/app/documentation/Introduction/index"));
const DocGetStart = lazy(() => import("./views/app/documentation/getStart"));
const DocBookingMeetings = lazy(() => import("./views/app/documentation/bookingMeetings/index"));
const DocExtendingMeetings = lazy(() => import("./views/app/documentation/extendingMeetings/index"));
const DocEndingMeetings = lazy(() => import("./views/app/documentation/endingMeetings/index"));
const DocPersonalGmail = lazy(() => import("./views/app/documentation/personalGmail/index"));
const DocViewingListofEvents = lazy(() => import("./views/app/documentation/viewingListofEvents/index"));
const DocTroubleshooting = lazy(() => import("./views/app/documentation/troubleshooting/index"));
const DocConfiguration = lazy(() => import("./views/app/documentation/configuration/index"));
const DocManageLicense = lazy(() => import("./views/app/documentation/manageLicense/index"));
const DocContactSupport = lazy(() => import("./views/app/documentation/contactandSupport/index"));
const DocLegalPrivacy = lazy(() => import("./views/app/documentation/legalandPrivacy/index"));
const DocConclusion = lazy(() => import("./views/app/documentation/conclusion/index"));
const DocFaq = lazy(() => import("./views/app/documentation/faq/index"));
const Profile = lazy(() => import("./views/app/profile/account/index"));
const UpdatePassword = lazy(() => import("./views/app/profile/updatePassword/index"));
const Subscription = lazy(() => import("./views/app/billing/subscription/index"));
const InvoiceHistory = lazy(() => import("./views/app/billing/invoiceHistory/index"));
const Payment = lazy(() => import("./views/app/billing/payment/index"));
const ExtraBilling = lazy(() => import("./views/app/billing/extraBilling/index"));
const Invoice = lazy(() => import("./views/app/billing/invoice/index"));

// superAdmin Route
const Dashboard = lazy(() => import("./views/admin/Dashboard/index"));
const Customer = lazy(() => import("./views/admin/customer/index"));
const AdminPayment = lazy(() => import("./views/admin/payment/index"));

function App() {

  const { data: profileList } = useProfileGetList();

  return (
    <Suspense
      fallback={
        <div className="loader">
          <Image name={"LoadingAnimation.gif"} />
        </div>
      }
    >
      <Routes>
        <Route element={<WebLayout />}>
          <Route exact path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/faq" element={<Faq />} />
          <Route
            path="public/documentation/introduction"
            element={<DocumentationIntroduction />}
          />
          <Route
            path="public/documentation/getstart"
            element={<DocGetStart />}
          />
          <Route
            path="public/documentation/booking-meetings"
            element={<DocBookingMeetings />}
          />
          <Route
            path="public/documentation/extending-meetings"
            element={<DocExtendingMeetings />}
          />
          <Route
            path="public/documentation/ending-meetings"
            element={<DocEndingMeetings />}
          />
          <Route
            path="public/documentation/personalgmail"
            element={<DocPersonalGmail />}
          />
          <Route
            path="public/documentation/viewing-listofevents"
            element={<DocViewingListofEvents />}
          />
          <Route
            path="public/documentation/troubleshooting"
            element={<DocTroubleshooting />}
          />
          <Route
            path="public/documentation/configuration"
            element={<DocConfiguration />}
          />
          <Route
            path="public/documentation/manage-license"
            element={<DocManageLicense />}
          />
          <Route
            path="public/documentation/contactandsupport"
            element={<DocContactSupport />}
          />
          <Route
            path="public/documentation/legalandprivacy"
            element={<DocLegalPrivacy />}
          />
          <Route
            path="public/documentation/conclusion"
            element={<DocConclusion />}
          />
        </Route>

        <Route element={<AuthLayout />}>
          <Route path="/login" element={<SignIn />} />
          <Route path="/register" element={<Register />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="/successfull-registration" element={<Success />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path="/delete-account" element={<DeletAccount />} />
          <Route path="/verifyanddelete" element={<VerifyAndDelete />} />
        </Route>

        <Route element={<AppLayout profileData={profileList} />}>
          <Route path="/licenses" element={<License profileData={profileList} />} />
          <Route path="/license_view/:id" element={<LicenseDetails />} />
          <Route path="/devices" element={<Device />} />
          <Route path="/configure/:id" element={<Configure />} />
          <Route path="/settings" element={<Settings />} />
          <Route
            path="private/documentation/introduction"
            element={<DocumentationIntroduction />}
          />
          <Route
            path="private/documentation/getstart"
            element={<DocGetStart />}
          />
          <Route
            path="private/documentation/booking-meetings"
            element={<DocBookingMeetings />}
          />
          <Route
            path="private/documentation/extending-meetings"
            element={<DocExtendingMeetings />}
          />
          <Route
            path="private/documentation/ending-meetings"
            element={<DocEndingMeetings />}
          />
          <Route
            path="private/documentation/personalgmail"
            element={<DocPersonalGmail />}
          />
          <Route
            path="private/documentation/viewing-listofevents"
            element={<DocViewingListofEvents />}
          />
          <Route
            path="private/documentation/troubleshooting"
            element={<DocTroubleshooting />}
          />
          <Route
            path="private/documentation/configuration"
            element={<DocConfiguration />}
          />
          <Route
            path="private/documentation/manage-license"
            element={<DocManageLicense />}
          />
          <Route
            path="private/documentation/contactandsupport"
            element={<DocContactSupport />}
          />
          <Route
            path="private/documentation/legalandprivacy"
            element={<DocLegalPrivacy />}
          />
          <Route
            path="private/documentation/conclusion"
            element={<DocConclusion />}
          />
          <Route path="private/documentation/faq" element={<DocFaq />} />
          <Route path="/profile/account" element={<Profile />} />
          <Route path="/profile/update-password" element={<UpdatePassword />} />
          <Route path="/billing/subscription" element={<Subscription />} />
          <Route path="/billing/history" element={<InvoiceHistory />} />
          <Route path="/billing/payment" element={<Payment />} />
          <Route path="/billing/extra-billing" element={<ExtraBilling profileData={profileList} />} />
          <Route path="/billing/invoice" element={<Invoice />} />
        </Route>

        <Route element={<AdminLayout/>}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/customer" element={<Customer />} />
          <Route path="/payment" element={<AdminPayment />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
