import React from "react";
import { Outlet } from 'react-router-dom';
import Image from 'helpers/Image';
import { BackTop } from 'antd';
import WebHeader from "./utils/web/WebHeader";
import WebFooter from "./utils/web/WebFooter";



export const WebLayout = () => {
  return (
    <div className="meeting-view">
      <WebHeader />
      <Outlet />
      <WebFooter />
      <BackTop prefixCls="" className="backTo_top"><button><Image name={"arrowUp.svg"} /></button></BackTop>
    </div>
  );
};

export default WebLayout;
