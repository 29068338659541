import React, { useState } from "react";
import { Row, Col, Button, Drawer } from "antd";
import { AlignRightOutlined } from "@ant-design/icons";
import Image from "helpers/Image";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";

const WebHeader = () => {

  let navigate = useNavigate();

  const pageScroll = (navId) => {
    if (window.location.pathname !== "/") {
      onClose();
      navigate("/");
      setTimeout(() => {
        document
          .getElementById(navId)
          .scrollIntoView({ behavior: "smooth", block: "start" });
      }, 300);
    } else {
      document
        .getElementById(navId)
        .scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };


  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };


  return (
    <header className="meeting-view-header">
      <Row className="meeting-view-header-main">
        <Col md={12} xs={12} lg={5}>
          <Row>
            <Col md={3} xs={24} lg={7}>
              <Link to="/">
                <Image name="logo.png" className="logo-img" />
              </Link>
            </Col>
            <Col md={0} xs={0} lg={10}>
              <h2>
                <Link to="/">MeetingView</Link>
              </h2>
            </Col>
          </Row>
        </Col>
        <Col md={0} xs={0} lg={14} className="header-links">
          <ul>
            <li onClick={() => pageScroll("advance-feature")}>Features</li>
            <li onClick={() => pageScroll("pricing")}>Pricing</li>
            <li onClick={() => pageScroll("tablet-minutes")}>Download</li>
            {/* <li onClick={() => pageScroll("contact")}>Contact Us</li> */}
            <li>
              <Link
                to={`public/documentation/introduction`}
                state={"publicRoute"}
                onClick={onClose}
              >
                Documentation
              </Link>
            </li>
          </ul>
        </Col>
        <Col md={0} xs={0} lg={5} className="header-btns">
          <Link to={`/login`}>Login</Link>
          <Link to={`/register`}>
            <Button>Try for free</Button>
          </Link>
        </Col>
        <Col md={12} xs={12} lg={0} className="txt-right">
          <AlignRightOutlined onClick={showDrawer} className="toggle-mobile" />
        </Col>
      </Row>

      <Drawer
        placement="top"
        onClose={onClose}
        open={open}
        closable={false}
        height={420}
      >
        <Col md={24} xs={24} lg={0} className="meeting-view-header-mobile">
          <Row className="proj-title">
            <Col md={1} xs={0} lg={0} />
            <Col md={2} xs={4} lg={0}>
              <Image
                onClick={window.scrollTo(0, 0)}
                name="logo.png"
                className="logo-img-mob"
              />
            </Col>
            <Col md={18} xs={20} lg={0}>
              <h2 onClick={window.scrollTo(0, 0)}>MeetingView</h2>
            </Col>
          </Row>
          <ul>
            <li
              onClick={() => {
                setTimeout(() => {
                  pageScroll("advance-feature");
                }, 100);
              }}
            >
              Features
            </li>
            <li
              onClick={() => {
                setTimeout(() => {
                  pageScroll("tablet-minutes");
                }, 100);
              }}
            >
              Download
            </li>
            <li
              onClick={() => {
                setTimeout(() => {
                  pageScroll("pricing");
                }, 100);
              }}
            >
              Pricing
            </li>
            {/* <li onClick={() => {
              setTimeout(() => {
                pageScroll("contact");
              }, 100);

            }}>Contact Us</li> */}
            <li>
              <Link
                to="public/documentation/introduction"
                state={"publicRoute"}
                onClick={onClose}
              >
                Documentation
              </Link>
            </li>
            <li>
              <Link to="/login">Login</Link>
            </li>
            <li>
              <Link to="/register">
                <Button>Try for Free</Button>
              </Link>
            </li>
          </ul>
        </Col>
      </Drawer>
    </header>
  );
};

export default WebHeader;