import React from 'react'
import { Outlet } from 'react-router-dom';
import AuthHeader from './utils/auth/authHeader';

function AuthLayout() {
    return (
        <div className="meeting-view-auth">
            <AuthHeader />
            <Outlet />
        </div>
    );
}

export default AuthLayout;