import React,{useEffect} from "react";
import { Row, Col } from "antd";
import { Link, useLocation } from "react-router-dom";
import Image from "helpers/Image";
import "./style.css";

const AuthHeader = () => {

  const location = useLocation();
    
  return (
    <header className="meeting-view-auth-header">
      <Row>
        <Col md={0} lg={2} xs={0} />
        <Col md={14} lg={14} xs={6}>
          <Row >
            <Col md={4} xs={24} lg={2}>
              <Link to="/">
                <Image name="logo.png" className="logo-img" />
              </Link>
            </Col>
            <Col md={8} xs={0} lg={10}>
              <h2><Link to="/">MeetingView</Link></h2>
            </Col>
          </Row>
        </Col>
        <Col md={10} lg={6} xs={18} className="txt-right">
          {/* <ul>
            <li>
              <Link to={`/login`}>Login</Link>
            </li>
            <li>
              <Link to={`/register`}>Register</Link>
            </li>
          </ul> */}
          {location.pathname === "/login" ? <p>
               Don't have an Account?  
                  <Link to="/register">
                             Sign Up Here.
                        </Link>
                      </p> :  <p>
                      Already have an account?
                      <Link to={`/login`}>
                             Sign In Here
                      </Link>
                    </p>}
        </Col>
      </Row>
    </header>
  );
};

export default AuthHeader;
