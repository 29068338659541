import React from "react";
import { Outlet } from "react-router-dom";
import { Row, Col } from "antd";
import SuperAdminHeader from "./utils/superAdmin/superAdminHeader";

export const AppLayout = () => {
  return (
    <div className="superAdmin-view">
      <Row>
        <Col md={24} xs={24}>
          <SuperAdminHeader />
        </Col>
        <Col md={24} xs={24}>
          <Outlet />
        </Col>
      </Row>
    </div>
  );
};

export default AppLayout;
