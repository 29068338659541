import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "services/profileServices/ProfileServices";

export const useProfileGetList = () => {
  return useQuery("ProfileGetList", () => api.profileGetList(), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: true,
  });
};


export const useUpdateProfile = () => {
  const queryClient = useQueryClient();
  return useMutation((formData) => api.updateProfile(formData), {
    onSuccess: () => {
      queryClient.refetchQueries('ProfileGetList');
    }
  });
}


export const useUpdatePassword = () => {
  return useMutation((formData) => api.updatePassword(formData));
}

export const useDeleteProfile = () => {
  return useMutation((formData) => api.deleteProfile(formData));
}
