import React, { Fragment,useState } from "react"
import { Row, Col,Modal,Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import Image from "helpers/Image";
import{ImportOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import "./style.css";

const SuperAdminHeader = () => {
  let navigate = useNavigate();
  const [isLogout, setIsLogout] = useState(false);
  const showLogoutModal = (e) => {
    e.preventDefault();
    setIsLogout(true);
  };
  const handleLogoutCancel = () => {
    setIsLogout(false);
  };
  const onLogout = (e) => {
    e.preventDefault()
    Cookies.remove('token_set');
    Cookies.remove('email');
    Cookies.remove('otp');
    navigate(`/home`);
  }
  return (
    <Fragment>
        <Row className="meeting-view-superAdmin-header">
        <Col md={2} xs={3} lg={1} >
          <Link to="/dashboard">
            <Image name="logo.png" className="logo-img" />
          </Link>
        </Col>
        <Col md={0} xs={0} lg={4}>
              <h2>
                <Link to="/dashboard">MeetingView</Link>
              </h2>
        </Col>
        <Col md={16} xs={0} lg={12}>
          <ul>
            <li><Link to="/dashboard">Dashboard</Link></li>
            <li><Link to={`/customer`}>Customer</Link></li>
            <li><Link to={`payment`} >Payment</Link></li>
          </ul>
        </Col>
        <Col md={16} xs={0} lg={7} className="txt-right">
          <div className="logoutfield">
          <div className="logout1" onClick={showLogoutModal}>
          <ImportOutlined />&nbsp;&nbsp;Log Out
        </div>
          </div>
       
        </Col>
      </Row>
      <Modal
        open={isLogout}
        onCancel={handleLogoutCancel}
        closable={false}
        className="logout_popup"
      >
        <p>Are you sure you want to Logout?</p>
        <Row gutter={[16, 16]}>
          <Col md={12}>
            <Button className="no_btn" onClick={handleLogoutCancel} block>
              No
            </Button>
          </Col>
          <Col md={12}>
            <Button className="yes_btn" onClick={(e) => onLogout(e)} block>
              Yes
            </Button></Col>
        </Row>
      </Modal>
    </Fragment>
  );
};

export default SuperAdminHeader;
